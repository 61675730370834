import React from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { Button, FloatButton } from 'antd';
import { useUserProvider } from '@/context/UserProvider';
import classNames from 'classnames';

const MeetMyBabyContent = () => {
  const { t } = useTranslation('common');
  const {
    // onOpenCreateReportAlert,
    onOpenSelectReportsAlert,
    isLogin,
    onGetStartedClick,
    onOpenLoginModal,
  } = useUserProvider();

  const onDashboardClick = () => {
    if (isLogin) {
      onOpenSelectReportsAlert(true);
      // onOpenCreateReportAlert(true);
    } else {
      onGetStartedClick("Single");
      onOpenLoginModal(true);
    }
  };

  return (
    <div>
      <FloatButton
        // type="primary"
        className={classNames(styles.floatBtnContent, 'custom-float-btn')}
        shape="square"
        style={{ right: 32 }}
        description={t(`Create Your Guide Today!`)}
        onClick={onDashboardClick}
      />

      <div className={styles.content}>
        <p className={styles.title}>{t(`planbowl_Title`)}</p>
        <div className={styles.btnContainer}>
          <Button type="primary" className={styles.signUpBtn} onClick={onDashboardClick}>
            {t('Create Your Guide Today!')}
          </Button>
          <span className={styles.tip}>{t('$19.9 Limited Offer')}</span>
        </div>
      </div>
    </div>
  );
};

export default MeetMyBabyContent;
